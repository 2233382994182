export enum AdminActionTypes {
    API_RESPONSE_SUCCESS = '@@admin/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR = '@@admin/API_RESPONSE_ERROR',
    FINANCIAL_YEAR = '@@admin/FINANCIAL_YEAR',
    USER_UPDATE = '@@admin/USER_UPDATE',
    USER_MANAGEMENT = '@@admin/USER_MANAGEMENT',
    DASHBOARD_RIGHTS = '@@admin/DASHBOARD_RIGHTS',
    SHORTCUT_RIGHTS = '@@admin/SHORTCUT_RIGHTS',
    MANAGE_LOGIN = '@@admin/MANAGE_LOGIN',
    EMP_RIGHTS = '@@admin/EMP_RIGHTS',
    ROLE = '@@admin/ROLE',
    RESET = '@@admin/RESET',
    CASHCOUNTER_RIGHTS = '@@admin/CASHCOUNTER_RIGHTS',
}
import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useViewport } from '../../../hooks';
function ShortcutLinks({ dashbaordRights, filteredButtonItems }) {
    console.log(filteredButtonItems);
    const goto = useNavigate();
    let { width } = useViewport();
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);
    const isAllowed = dashbaordRights.some((right) => right.value === 28) && filteredButtonItems?.length !== 0;

    return (
        <div>
            {isAllowed && (
                <div
                    className={`d-flex bg-light ps-3 mobile-scroll`} // Add the mobile-scroll class here
                    style={{
                        alignItems: 'center',
                        padding: '5px',
                        borderRadius: '30px',
                        marginBottom: '10px',
                    }}>
                    {(filteredButtonItems || []).map(({ label, url, name, icon, color }, i) => (
                        <div className={`flex mx-1 ${color == null ? 'text-info' : color}`} key={i}>
                            <OverlayTrigger placement="top" overlay={<Tooltip>{label}</Tooltip>}>
                                <i
                                    className={`${icon} me-1 cursor-pointer`}
                                    style={{ fontSize: '1.2rem' }}
                                    onClick={() => goto(`/${url}`)}></i>
                            </OverlayTrigger>
                        </div>
                    ))}
                    {filteredButtonItems?.length != 0 ? (
                        <i
                            className="dripicons-information text-info cursor-pointer"
                            style={{ fontSize: '1.2rem', marginTop: '3px' }}
                            onClick={toggle}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            )}

            <Offcanvas show={show} onHide={toggle} style={{ borderRadius: '0px' }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="mb-0">User Guides</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        This shortcut bar contains various links for transaction forms, reports and idenetity forms for
                        easy access
                    </div>
                    <h5 className="mt-3">Guidelines</h5>
                    <ul className="ps-3">
                        <li>
                            <span className="text-success">Green</span> icons represent <b>Sales Forms and Reports</b>{' '}
                            used to create, manage, and view sales-related transactions and summaries.
                        </li>
                        <li>
                            <span className="text-warning">Yellow</span> icons are for <b>Purchase Forms and Reports</b>{' '}
                            used to create, manage, and view purchase-related transactions and summaries.
                        </li>
                        <li>
                            <span className="text-primary">Blue</span> icons signify <b>Account Forms and Reports</b>{' '}
                            for managing and viewing account-related details and reports.
                        </li>
                        <li>
                            <span className="text-danger">Red</span> icons indicate <b>Identity Forms</b> used for
                            managing items, customers, suppliers, and accounts forms.
                        </li>
                    </ul>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}
export default ShortcutLinks;

// constants
import { ItmmngtActionTypes } from './constants';

export type ItmmngtActionType = {
    type:
        | ItmmngtActionTypes.API_RESPONSE_SUCCESS
        | ItmmngtActionTypes.API_RESPONSE_ERROR
        | ItmmngtActionTypes.RESET
        | ItmmngtActionTypes.ADD_ITEM
        | ItmmngtActionTypes.ADD_ITEMUNIT
        | ItmmngtActionTypes.ADD_ITEMBRAND
        | ItmmngtActionTypes.ADD_ITEMCODE
        | ItmmngtActionTypes.ADD_ITEMVARIETY
        | ItmmngtActionTypes.ADD_ITEMTYPE
        | ItmmngtActionTypes.MULTIPLE_ITEMS
        | ItmmngtActionTypes.ADD_ITEMCAT
        | ItmmngtActionTypes.EDIT_ITEMCAT
        | ItmmngtActionTypes.EDIT_ITEM
        | ItmmngtActionTypes.FORM_LOC
        | ItmmngtActionTypes.MULTIPLE_ITEMS_UPD
        | ItmmngtActionTypes.UPD_OS 
        | ItmmngtActionTypes.ADD_ITEMSUBCAT 
        | ItmmngtActionTypes.ADD_OS
        | ItmmngtActionTypes.ADD_TRANSFER
        | ItmmngtActionTypes.UPD_TRANSFER
        | ItmmngtActionTypes.ADD_ADJUSTMENT
        | ItmmngtActionTypes.UPD_ADJUSTMENT
        | ItmmngtActionTypes.ADD_STOCKIN
        | ItmmngtActionTypes.UPD_STOCKIN
        | ItmmngtActionTypes.ADD_STOCKOUT
        | ItmmngtActionTypes.UPD_STOCKOUT
        ;
    payload: {} | string;
};

type ItemData = {
    itemcodetype:string;id:string; itemcode: string; barcode: string; description: string; UrduDescription:string; itemtype:string; categoryid:string; brandid:string; masterpackingunitid:string;  unitprice:string; price1:string; itemcosteach:string; notes:string;minstocklevel:string;codetype:string;allowdis:string;subcatid:string;pcsperpack:string;packpermaster:string;unitweight:string;image:any;negativestock:string;
};
type UnitData = {
    unitname:string; 
};
type BrandData = {
    name:string; 
};
type TypeData = {
    type:string; 
};



// common success
export const itmmngtApiResponseSuccess = (actionType: string, data: ItemData| {}): ItmmngtActionType => ({
    type: ItmmngtActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const itmmngtApiResponseError = (actionType: string, error: string): ItmmngtActionType => ({
    type: ItmmngtActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addItem = (itemcodetype:string,itemcode:string, barcode: string, description:string, UrduDescription:string, itemtype:string, categoryid:string, brandid:string, masterpackingunitid:string,  unitprice:string, price1:string, itemcosteach:string, notes:string,minstocklevel:string,codetype:string,allowdis:string,subcatid:string,pcsperpack:string,packpermaster:string,unitweight:string,pcsperpackunitid:string,packpermasterunitid:string,negativestock:string,isservice:string): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_ITEM,
    payload: {itemcodetype, itemcode, barcode, description, UrduDescription, itemtype, categoryid, brandid, masterpackingunitid, unitprice, price1, itemcosteach, notes,minstocklevel,codetype,allowdis,subcatid,pcsperpack,packpermaster,unitweight,pcsperpackunitid,packpermasterunitid,negativestock,isservice},
});

export const updItem = (id:string,itemcodetype:string, itemcode:string,  barcode: string, description:string, UrduDescription:string, itemtype:string, categoryid:string, brandid:string, masterpackingunitid:string,  unitprice:string, price1:string, itemcosteach:string, notes:string,minstocklevel:string,codetype:string,allowdis:string,subcatid:string,pcsperpack:string,packpermaster:string,unitweight:string,pcsperpackunitid:string,packpermasterunitid:string,image:any,negativestock:string,isservice:string,): ItmmngtActionType => ({
    type: ItmmngtActionTypes.EDIT_ITEM,
    payload: {id,itemcodetype, itemcode, barcode, description, UrduDescription, itemtype, categoryid, brandid, masterpackingunitid, unitprice, price1, itemcosteach, notes,minstocklevel,codetype,allowdis,subcatid,pcsperpack,packpermaster,unitweight,pcsperpackunitid,packpermasterunitid,image,negativestock,isservice},
});

export const addInvUnit = (unitname: string): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_ITEMUNIT,
    payload: { unitname },
});
export const addOpeningStock = (ITDate: string, DetailedDescription:string, osdetdata:object): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_OS,
    payload: { ITDate, DetailedDescription, osdetdata },
});

export const updateOpeningStock = (id:string,ITDate: string, DetailedDescription:string, osdetdata:object): ItmmngtActionType => ({
    type: ItmmngtActionTypes.UPD_OS ,
    payload: {id, ITDate, DetailedDescription, osdetdata },
});

export const addStockAdjustment = (ITDate: string, DetailedDescription:string, adjdetdata:object): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_ADJUSTMENT,
    payload: { ITDate, DetailedDescription, adjdetdata },
});

export const updateStockAdjustment = (id:string,ITDate: string, DetailedDescription:string, adjdetdata:object): ItmmngtActionType => ({
    type: ItmmngtActionTypes.UPD_ADJUSTMENT ,
    payload: {id, ITDate, DetailedDescription, adjdetdata },
});

export const addStockIn = (ITDate: string, DetailedDescription:string, adjdetdata:object): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_STOCKIN,
    payload: { ITDate, DetailedDescription, adjdetdata },
});

export const updateStockIn = (id:string,ITDate: string, DetailedDescription:string, adjdetdata:object): ItmmngtActionType => ({
    type: ItmmngtActionTypes.UPD_STOCKIN ,
    payload: {id, ITDate, DetailedDescription, adjdetdata },
});


export const addStockOut = (ITDate: string, DetailedDescription:string, adjdetdata:object): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_STOCKOUT,
    payload: { ITDate, DetailedDescription, adjdetdata },
});

export const updateStockOut = (id:string,ITDate: string, DetailedDescription:string, adjdetdata:object): ItmmngtActionType => ({
    type: ItmmngtActionTypes.UPD_STOCKOUT ,
    payload: {id, ITDate, DetailedDescription, adjdetdata },
});

export const addStockTransfer = (ITDate: string, DetailedDescription:string, transdetdata:object): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_TRANSFER,
    payload: { ITDate, DetailedDescription, transdetdata },
});

export const updateStockTransfer = (id:string,ITDate: string, DetailedDescription:string, transdetdata:object): ItmmngtActionType => ({
    type: ItmmngtActionTypes.UPD_TRANSFER ,
    payload: {id, ITDate, DetailedDescription, transdetdata },
});

export const addItemBrand = (name: string): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_ITEMBRAND,
    payload: { name },
});
export const addItemCode = (itemcodetype:string,setcode: string,setnumber: string,codetype:string,defaultitem:string): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_ITEMCODE,
    payload: {itemcodetype, setcode,setnumber ,codetype,defaultitem},
});
export const addItemType = (type: string): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_ITEMTYPE,
    payload: { type },
});
export const addItemCategory = (name: string): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_ITEMCAT,
    payload: { name },
});
export const addItemSubCategory = (catid:string,name: string): ItmmngtActionType => ({
    type: ItmmngtActionTypes.ADD_ITEMSUBCAT,
    payload: { catid,name },
});
export const addMultipleItem = (data_array)=> ({
    type: ItmmngtActionTypes.MULTIPLE_ITEMS,
    payload: {data_array},
});
export const updMultipleItem = (data_array,id)=> ({
    type: ItmmngtActionTypes.MULTIPLE_ITEMS_UPD,
    payload: {data_array,id},
});

export const manageFormLoc = (name: string,address: string,city: string,country: string,phone: string,email: string,phonecode:string): ItmmngtActionType => ({
    type: ItmmngtActionTypes.FORM_LOC,
    payload: { name,address,city,country,phone,email,phonecode},
});


export const resetItmmngt = (): ItmmngtActionType => ({
    type: ItmmngtActionTypes.RESET,
    payload: {},
});

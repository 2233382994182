import { APICore } from './apiCore';
const api = new APICore();
function addServices_API(params: {
    orderid: string;
    entrydate: string;
    name: string;
    phone: string;
    address: string;
    sourceid: string;
    customerid: string;
    salestype: string;
    servicepersonid: string;
    reference: string;
    notes: string;
    discount: string;
    amount: string;
    netamount: string;
    toolid: string;
    tax: string;
    taxamount: string;
    bankreceived: string;
    bankid: string;
    cashreceived: string;
    pickuppostal: string;
    pickupaddress: string;
    dropoffpostal: string;
    dropoffaddress: string;
    phonecode:string;
    orderdetdata: Object;
}) {
    const baseUrl = '/add-services-invoice';
    return api.create(`${baseUrl}`, params);
}
function addServicesOrder_API(params: {
    entrydate: string;
    sourceid: string;
    customerid: string;
    salestype: string;
    servicepersonid: string;
    reference: string;
    notes: string;
    discount: string;
    amount: string;
    netamount: string;
    toolid: string;
    tax: string;
    taxamount: string;
    pickuppostal: string;
    pickupaddress: string;
    dropoffpostal: string;
    dropoffaddress: string;
    dropoffdate: string;
    pickupdate: string;
    deliverydate: string;
    orderdetdata: Object;
}) {
    const baseUrl = '/add-servicesorder';
    return api.create(`${baseUrl}`, params);
}
function addQuery_API(params: { name: string }) {
    const baseUrl = '/add-querysource';
    return api.create(`${baseUrl}`, params);
}
function addStage_API(params: { name: string; percentage: string }) {
    const baseUrl = '/add-orderstage';
    return api.create(`${baseUrl}`, params);
}
function addTools_API(params: { name: string }) {
    const baseUrl = '/add-toolsmachine';
    return api.create(`${baseUrl}`, params);
}
function addServicesPerson_API(params: { name: string }) {
    const baseUrl = '/add-servicesperson';
    return api.create(`${baseUrl}`, params);
}

function updToolsExpence_Voucher(params) {
    //console.log('update upd Expence_Voucher---');
    const baseUrl = `/update-toolsexpense-voucher/${params.id}`;
    return api.update(`${baseUrl}`, params);
}

function updServicesProgress_API(params) {
    //console.log('update upd Expence_Voucher---');
    const baseUrl = `/update-orderprogress/${params.id}`;
    return api.update(`${baseUrl}`, params);
}

function addToolsExpence_Voucher(params) {
    console.log('add Expence_Voucher---');
    const baseUrl = '/add-toolsexpense-voucher';
    return api.create(`${baseUrl}`, params);
}
function updServices_API(params: {
    id: string;
    orderid: string;
    entrydate: string;
    name: string;
    phone: string;
    address: string;
    sourceid: string;
    customerid: string;
    salestype: string;
    servicepersonid: string;
    reference: string;
    notes: string;
    discount: string;
    amount: string;
    netamount: string;
    toolid: string;
    tax: string;
    taxamount: string;
    bankreceived: string;
    bankid: string;
    cashreceived: string;
    pickuppostal: string;
    pickupaddress: string;
    dropoffpostal: string;
    dropoffaddress: string;
    phonecode:string;
    orderdetdata: Object;
}) {
    const baseUrl = `/update-servicesinvoice/${params.id}`;
    return api.create(`${baseUrl}`, params);
}
function updServicesOrder_API(params: {
    id: string;
    entrydate: string;
    sourceid: string;
    customerid: string;
    salestype: string;
    servicepersonid: string;
    reference: string;
    notes: string;
    discount: string;
    amount: string;
    netamount: string;
    toolid: string;
    tax: string;
    taxamount: string;
    pickuppostal: string;
    pickupaddress: string;
    dropoffpostal: string;
    dropoffaddress: string;
    dropoffdate: string;
    pickupdate: string;
    deliverydate: string;
    orderdetdata: Object;
}) {
    const baseUrl = `/update-servicesorder/${params.id}`;
    return api.create(`${baseUrl}`, params);
}
export {
    addServicesOrder_API,
    addStage_API,
    updServicesOrder_API,
    addServices_API,
    updServices_API,
    addQuery_API,
    addTools_API,
    addServicesPerson_API,
    addToolsExpence_Voucher,
    updToolsExpence_Voucher,
    updServicesProgress_API,
};
